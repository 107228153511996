<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="loans"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>

        <span v-else-if="props.column.field === 'is_paid'">
          {{ status[props.row.is_paid] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'

import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import apiCall from "@/libs/axios";
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv';
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name: 'EmployeeLoan',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Loan month'),
          field: 'month_year',
        },
        {
          label: this.$t('Installment'),
          field: 'issue_installment',
        },
        {
          label: this.$t('Payable installment'),
          field: 'payable_installment',
        },
        {
          label: this.$t('Loan amount'),
          field: 'loan_amount',
        },
        {
          label: this.$t('Payable amount'),
          field: 'payable_amount',
        },
        {
          label: this.$t('Is Paid?'),
          field: 'is_paid',
        },
      ],
      searchTerm: '',
      status: {
        1: this.$t('Yes'),
        0: this.$t('No'),
      },
      employee: {},
      amount: {},
      columns10: [
        {
          label: 'Loan Month',
          field: 'LoanMonth',
        },
        {
          label: 'Installment',
          field: 'Installment',
        },
        {
          label: 'Payable Installment',
          field: 'PayableInstallment',
        },
        {
          label: 'Loan Amount',
          field: 'LoanAmount',
        },
        {
          label: 'Payable Amount',
          field: 'PayableAmount',
        },
        {
          label: 'IS Paid',
          field: 'ISPaid',
        },
      ],
    }
  },
  methods: {
    pdfGenerate() {
      let title = "Loan";
      let clm = ['LoanMonth','Installment','PayableInstallment','LoanAmount','PayableAmount', 'ISPaid' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'loan_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['loans']),
    getData() {
      return this.loans.map(item => ({
        LoanMonth: item.month_year ? item.month_year : '',
        Installment: item.issue_installment ? item.issue_installment : 0,
        PayableInstallment: item.payable_installment ? item.payable_installment : 0,
        LoanAmount: item.loan_amount ? item.loan_amount : 0,
        PayableAmount: item.payable_amount ? item.payable_amount : 0,
        ISPaid: item.is_paid ? 'Yes' : 'No',
      }));
    }
  },
  created() {
    if (this.loans.length < 1) this.$store.dispatch('GET_EMPLOYEE_LOAN');
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>